import React from "react"

const TermsOfUsePage = () => (
  <>
    <div className="container">
      <br />
      <div className="column mb-6">
        <h1 className="is-size-2 has-text-primary has-text-centered has-text-weight-bold">
          Terms of Use
        </h1>
        <p className="is-size-6 has-text-centered has-text-weight-semibold">
          Last Updated on: 06/15/2020
        </p>

        <br />
        <p className="is-size-5 has-text-weight-semibold mt-5">
          Using Archimydes means an acceptance of our terms.
        </p>
        <p className="is-size-5 has-text-weight-bold mt-5">
          Acceptance of Terms:
        </p>
        <p>
          a. Archimydes, a Archimydes Inc. product (“Archimydes” or “we”),
          provides its Service (as defined below) to you through its web site
          located at archimydes.com (the “Site”), subject to this Terms of
          Service agreement (“TOS”). By accepting this TOS or by accessing or
          using the Service or Site, you acknowledge that you have read,
          understood, and agree to be bound by this TOS. If you are entering
          into this TOS on behalf of a company, business or other legal entity,
          you represent that you have the authority to bind such entity and its
          affiliates to this TOS, in which case the terms “you” or “your” shall
          refer to such entity and its affiliates. If you do not have such
          authority, or if you do not agree with this TOS, you must not accept
          this TOS and may not use the Service.
        </p>
        <p className="mt-4">
          b. Archimydes may change this TOS from time to time by providing
          thirty (30) days prior notice either by emailing the email address
          associated with your account or by posting a notice on the Site. You
          can review the most current version of this TOS at any time via
          Archimydes.com. The revised terms and conditions will become effective
          thirty (30) days after we post or send you notice of such changes, and
          if you use the Service after that date, your use will constitute
          acceptance of the revised terms and conditions. If any change to this
          TOS is not acceptable to you, your only remedy is stop using the
          Services.
        </p>
        <p className="mt-4">
          c. As part of the registration process, you will identify an
          administrative username and password for your account (“Account”).
        </p>

        <p className="is-size-5 has-text-weight-bold mt-5">
          Description of Service:
        </p>
        <p>
          The “Service(s)” includes (a) the Site, (b) Archimydes’s
          software-as-a-service platform for developing user stories and
          managing software development projects and (c) all software (including
          the Software, as defined below), data, reports, text, images, sounds,
          video, and content made available through any of the foregoing
          (collectively referred to as the “Content”). Any new features added to
          or augmenting the Service are also subject to this TOS.
        </p>

        <p className="is-size-5 has-text-weight-bold mt-5">Additional Terms:</p>
        <p>
          The Service is designed to manage software development projects and
          deliverables for Customers and may be subject to additional terms,
          including payment terms at the time of purchase.
        </p>

        <p className="is-size-5 has-text-weight-bold mt-5">
          Access and Use of Service:
        </p>
        <p>
          a. <u>Authorization to Access and Use Site and Content:</u> Subject to
          your compliance with these TOS and the provisions hereof, you may
          access or use the Site and Content solely for the purpose of your
          evaluation of Archemydes and Archimydes’ products and services. You
          may only link to the Site or Content, or any portion thereof, as
          expressly permitted by Archemydes. All rights, title and interest in
          and to the Service and its components will remain with and belong
          exclusively to Archimydes.
        </p>
        <p className="mt-4">
          b. <u>Restrictions:</u> You will not (a) sublicense, resell, rent,
          lease, transfer, assign, time share or otherwise commercially exploit
          or make the Site and any Content available to any third party, (b) use
          the Site and Content in any unlawful manner (including without
          limitation in violation of any data, privacy or export control laws)
          or in any manner that interferes with or disrupts the integrity or
          performance of the Site and Content or their related components, or
          (c) modify, adapt or hack the Site and Content to, or try to, gain
          unauthorized access to the restricted portions of the Site and Content
          or related systems or networks (i.e., circumvent any encryption or
          other security measures, gain access to any source code or any other
          underlying form of technology or information, and gain access to any
          part of the Site and Content, or any other products or services of
          Archimydes that are not readily made available to the general public).
        </p>
        <p className="mt-4">
          c. Any software that may be made available by Archemydes in connection
          with the Service (“Software”) contains proprietary and confidential
          information that is protected by applicable intellectual property and
          other laws. Subject to the terms and conditions of this TOS,
          Archimydes hereby grants you a non-transferable, non-sublicensable and
          non-exclusive right and license to use the object code of any Software
          on a single device solely in connection with the Service, provided
          that you shall not (and shall not allow any third party to) copy,
          modify, create a derivative work of, reverse engineer, reverse
          assemble or otherwise attempt to discover any source code or sell,
          assign, sublicense or otherwise transfer any right in any Software.
          You agree not to access the Service by any means other than through
          the interface that is provided by Archemydes for use in accessing the
          Service. You may not use any “page-scrape,” “deep-link,” “spider,” or
          “robot or other automatic program, device, algorithm or methodology,
          or any similar manual process, to access, copy, acquire, or monitor
          any portion of the Site or any Content, or in any way reproduce or
          circumvent the presentation or navigational structure of the Site or
          any Content, to obtain or attempt to obtain any Content or other
          information through any means not made generally available through the
          Site by Archimydes. Archimydes reserves the right to take any lawful
          measures to prevent any such activity. You may not forge headers or
          otherwise manipulate identifiers in order to disguise the origin of
          any message or transmittal you send to Archimydes on or through the
          Site or any service offered on or through the Site. You may not
          pretend that you are, or that you represent, someone else, or
          impersonate any other individual or entity. Any rights not expressly
          granted herein are reserved and no license or right to use any
          trademark of Archimydes or any third party is granted to you in
          connection with the Service.
        </p>
        <p className="mt-4">
          d. You are solely responsible for all data, information, feedback,
          suggestions, text, content and other materials that you upload, post,
          deliver, provide or otherwise transmit or store (hereafter
          “post(ing)”) in connection with or relating to the Service (“Your
          Content”). You agree to cooperate with and provide reasonable
          assistance to Archimydes in promoting and advertising the Services.
        </p>
        <p className="mt-4">
          e. You are responsible for maintaining the confidentiality of your
          login, password and account and for all activities that occur under
          your login or account. Archimydes reserves the right to access your
          account in order to respond to your requests for technical support. By
          posting Your Content on or through the Service, you hereby do and
          shall grant Archimydes a worldwide, non-exclusive, perpetual,
          irrevocable, royalty-free, fully paid, sublicensable and transferable
          license to use, modify, reproduce, distribute, display, publish and
          perform Your Content solely to provide the Services. Archimydes has
          the right, but not the obligation, to monitor the Service, Content, or
          Your Content. You further agree that Archimydes may remove or disable
          any Content at any time for any reason (including, but not limited to,
          upon receipt of claims or allegations from third parties or
          authorities relating to such Content), or for no reason at all.
        </p>
        <p className="mt-4">
          f. You understand that the operation of the Service, including Your
          Content, may be unencrypted and involve (a) transmissions over various
          networks; (b) changes to conform and adapt to technical requirements
          of connecting networks or devices and (c) transmission to Archimydes’
          third party consultants, vendors and hosting partners to provide the
          necessary hardware, software, networking, storage, and related
          technology required to operate and maintain the Service. Accordingly,
          you acknowledge that you bear sole responsibility for adequate
          security, protection and backup of Your Content. Archimydes will have
          no liability to you for any unauthorized access or use of any of Your
          Content, or any corruption, deletion, destruction or loss of any of
          Your Content.
        </p>
        <p className="mt-4">
          g. You shall be responsible for obtaining and maintaining any
          equipment and ancillary services needed to connect to, access or
          otherwise use the Services, including, without limitation, modems,
          hardware, server, software, operating system, networking, web servers,
          long distance and local telephone service (collectively, “Equipment”).
          You shall be responsible for ensuring that such Equipment is
          compatible with the Services (and, to the extent applicable, the
          Software) and complies with all configurations and specifications set
          forth in Archimydes' published policies then in effect. You shall also
          be responsible for maintaining the security of the Equipment, your
          Account, passwords (including but not limited to administrative and
          user passwords) and files, and for all uses of your Account or the
          Equipment with or without your knowledge or consent.
        </p>
        <p className="mt-4">
          h. The failure of Archimydes to exercise or enforce any right or
          provision of this TOS shall not be a waiver of that right. You
          acknowledge that this TOS is a contract between you and Archimydes,
          even though it is electronic and is not physically signed by you and
          Archimydes, and it governs your use of the Service.
        </p>

        <p className="is-size-5 has-text-weight-bold mt-5">
          Representations and Warranties:
        </p>
        <p>
          You represent and warrant to Archimydes that (i) you have full power
          and authority to enter into this TOS; (ii) you own all Your Content or
          have obtained all permissions, releases, rights or licenses required
          to engage in your posting and other activities (and allow Archimydes
          to perform its obligations) in connection with the Services without
          obtaining any further releases or consents; (iii) Your Content and
          other activities in connection with the Service, and Archimydes'
          exercise of all rights and license granted by you herein, do not and
          will not violate, infringe, or misappropriate any third party’s
          copyright, trademark, right of privacy or publicity, or other personal
          or proprietary right, nor does Your Content contain any matter that is
          defamatory, obscene, unlawful, threatening, abusive, tortious,
          offensive or harassing; and (iv) you are eighteen (18) years of age or
          older.
        </p>
        <p className="is-size-5 has-text-weight-bold mt-5">
          Disclaimer of Warranties:
        </p>
        <p>
          THE SITE AND CONTENT, AND ALL SERVER AND NETWORK COMPONENTS, ARE
          PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY WARRANTIES
          OF ANY KIND, AND ACHIMYDES EXPRESSLY DISCLAIMS ALL OTHER
          REPRESENTATIONS AND WARRANTIES, INCLUDING ANY IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT,
          AND ANY REPRESENTATIONS OR WARRANTIES ARISING FROM COURSE OF DEALING,
          COURSE OF PERFORMANCE OR USAGE OF TRADE. YOU ACKNOWLEDGE THAT
          Archimydes DOES NOT WARRANT THAT YOUR ACCESS OR USE OR BOTH OF THE THE
          SITE AND CONTENT WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE OR
          VIRUS-FREE, AND Archimydes DOES NOT MAKE ANY WARRANTY AS TO THE
          RESULTS THAT MAY BE OBTAINED FROM USE OF THE SITE AND CONTENT, AND NO
          INFORMATION, ADVICE OR SERVICES OBTAINED BY YOU FROM Archimydes OR
          THROUGH THE SITE AND PROPERTY WILL CREATE ANY WARRANTY NOT EXPRESSLY
          STATED IN THESE TOS.
        </p>
        <p className="mt-4">
          Archimydes reserves the right to do any of the following, at any time,
          without notice: (1) to modify, suspend or terminate operation of or
          access to the Site, or any portion of the Site, for any reason; (2) to
          modify or change the Site, or any portion of the Site, for any reason;
          and (3) to interrupt the operation of the Site, or any portion of the
          Site, as necessary to perform routine or non-routine maintenance,
          error correction, or other changes.
        </p>
        <p className="is-size-5 has-text-weight-bold mt-5">
          Limitations of Liability:
        </p>
        <p>
          a. Exclusion of Damages and Limitation of Liability: Archimydes does
          not charge fees for you to access and use the Site and Content
          pursuant to these TOS. As consideration for your free access and use
          of the Site and Content pursuant to these TOS, you further agree that
          Archimydes WILL NOT BE LIABLE TO YOU FOR ANY INCIDENTAL,
          CONSEQUENTIAL, INDIRECT, SPECIAL, PUNITIVE OR EXEMPLARY DAMAGES
          (INCLUDING DAMAGES FOR LOSS OF BUSINESS, LOSS OF PROFITS OR THE LIKE)
          ARISING OUT OF OR RELATING TO THIS TOS, INCLUDING WITHOUT LIMITATION,
          YOUR USE OR INABILITY TO USE THE SITE, PLATFORM, MATCHING SERVICES,
          CONTENT, PROPRIETARY INFORMATION, OR ANY INTERRUPTION OR DISRUPTION OF
          SUCH USE, EVEN IF Archimydes HAS BEEN ADVISED OF THE POSSIBILITY OF
          SUCH DAMAGES AND REGARDLESS OF THE CAUSE OF ACTION (WHETHER IN
          CONTRACT, TORT, BREACH OF WARRANTY OR OTHERWISE). THE AGGREGATE
          LIABILITY OF Archimydes WITH REGARD TO THIS TOS WILL IN NO EVENT
          EXCEED USD$1.00.
        </p>
        <p className="mt-4">
          b. Some states and other jurisdictions do not allow the exclusion of
          implied warranties or limitation of liability for incidental or
          consequential damages, which means that some of the above limitations
          may not apply to you. IN THESE STATES, ARCHIMYDES’ LIABILITY WILL BE
          LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
        </p>
        <p className="mt-4">
          c. Dispute Resolution; Jury Waiver. THESE TOS ARE MADE UNDER, AND WILL
          BE CONSTRUED AND ENFORCED IN ACCORDANCE WITH, THE LAWS OF DELAWARE
          APPLICABLE TO AGREEMENTS MADE AND TO BE PERFORMED SOLELY THEREIN,
          WITHOUT GIVING EFFECT TO PRINCIPLES OF CONFLICTS OF LAW. In any action
          between or among any of the parties, whether arising out of these T&Cs
          or otherwise, each of the parties irrevocably and unconditionally (a)
          consents and submits to the exclusive jurisdiction and venue of the
          state and federal courts located in Wilmington Delaware; and (b)
          WAIVES ANY AND ALL RIGHT TO TRIAL BY JURY IN ANY LEGAL PROCEEDING
          ARISING OUT OF OR RELATED TO THIS AGREEMENT OR ANY TRANSACTIONS
          CONTEMPLATED HEREBY.
        </p>
        <p className="is-size-5 has-text-weight-bold mt-5">Indemnification:</p>
        <p>
          You shall defend, indemnify, and hold harmless Archimydes from and
          against any claims, actions or demands, including without limitation
          reasonable legal and accounting fees, arising or resulting from your
          breach of this TOS, any of Your Content, or your other access,
          contribution to, use or misuse of the Service. Archimydes shall
          provide notice to you of any such claim, suit or demand. Archimydes
          reserves the right to assume the exclusive defense and control of any
          matter which is subject to indemnification under this section. In such
          case, you agree to cooperate with any reasonable requests assisting
          Archimydes’s defense of such matter.
        </p>
        <p className="is-size-5 has-text-weight-bold mt-5">Miscellaneous:</p>
        <p>
          If any provision of this TOS is found to be unenforceable or invalid,
          that provision will be limited or eliminated to the minimum extent
          necessary so that this TOS will otherwise remain in full force and
          effect and enforceable. Both parties agree that this TOS and any
          Additional Terms as applicable, are the complete and exclusive
          statement of the mutual understanding of the parties and supersedes
          and cancels all previous written and oral agreements, communications
          and other understandings relating to the subject matter of this TOS,
          and that all waivers and modifications must be in a writing signed by
          both parties, except as otherwise provided herein. No agency,
          partnership, joint venture, or employment is created as a result of
          this TOS and you do not have any authority of any kind to bind
          Archimydes in any respect whatsoever. In any action or proceeding to
          enforce rights under this TOS, the prevailing party will be entitled
          to recover costs and attorneys’ fees. All notices under this TOS will
          be in writing and will be deemed to have been duly given when
          received, if personally delivered; when receipt is electronically
          confirmed, if transmitted by facsimile or email; the day after it is
          sent, if sent for next day delivery by recognized overnight delivery
          service; and upon receipt, if sent by certified or registered mail,
          return receipt requested.
        </p>
        <p className="is-size-5 has-text-weight-bold mt-5">Governing Law:</p>
        <p>
          This TOS shall be governed by the laws of the State of New York
          without regard to the principles of conflicts of law. Unless otherwise
          elected by Archimydes in a particular instance, you hereby expressly
          agree to submit to the exclusive personal jurisdiction of the federal
          and state courts of the State of New York or the purpose of resolving
          any dispute relating to your access to or use of the Service.
        </p>
      </div>
    </div>
  </>
)

export default TermsOfUsePage
