import React from "react"
import { ThemeProvider } from "styled-components"
import Layout from "src/components/layout"
import TermsOfUsePage from "src/components/terms-of-use/terms-of-use"
import { Banner } from "src/components/Banner"
import SEO from "src/components/seo"
import "src/components/common/custom-bluma.scss"
import { ClientHeader } from "src/components/Header"

import Container from "src/components/Container"
import { darkTheme } from "src/utils/themes"

const TOSPage = ({ location }) => (
  <ThemeProvider theme={darkTheme}>
    <Layout>
      <SEO title="Terms of Use" location={location} />
      <Banner>
        <Container>
          <ClientHeader />
        </Container>
      </Banner>
      <TermsOfUsePage />
    </Layout>
  </ThemeProvider>
)

export default TOSPage
